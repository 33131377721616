import React from 'react';
import { Link } from 'react-router-dom';
import '../css/AboutPage.css';

const AboutPage = () => {
  return (
    <main className="about-page">

      {/* Intro Section */}
      <section className="about-intro">
        <h1>About Us</h1>
        <p>
          Welcome to <strong>The Most Magical Travel Co</strong>, where planning your Disney adventure becomes effortless and magical. 
          We’re like having that family member who always knows the ins and outs of Disney trips—the best hotels, FastPass systems, 
          and insider tips—so you don’t have to stress about it.
        </p>
      </section>

      {/* Founder Section */}
      <section className="about-founder">
        <div className="founder-image">
          <img src="/images/amanda.png" alt="Amanda, founder of The Most Magical Travel Co" />
        </div>
        <div className="founder-bio">
          <h2>Meet Amanda</h2>
          <p>
            Amanda, a lifelong Disney enthusiast and loving mother of three, founded <strong>The Most Magical Travel Co</strong>. 
            Along with her husband Patrick, Amanda visits Disney multiple times a year. She’s passionate about helping families create unforgettable experiences without the overwhelm of planning every detail.
          </p>
          <p>
            Whether it’s finding the perfect hotel, navigating ticket systems, or being on-call during your trip, Amanda and her team are here to guide you through every step of your magical journey.
          </p>
        </div>
      </section>

      {/* Call-to-Action */}
      <section className="about-cta">
        <p>
          Ready to plan your magical adventure?{' '}
          <Link to="/contact" className="cta-link">Contact us today!</Link>
        </p>
      </section>
    </main>
  );
};

export default AboutPage;
