import React from 'react';
import ContactForm from './ContactForm';
import '../css/ContactPage.css';

const ContactPage = () => {
  return (
    <main className="contact-page">
      <section className="contact-intro">
        <h2>Contact Us</h2>
        <p>
          Ready to start planning your magical adventure? Reach out to us today, and we'll guide you through every step of creating an unforgettable experience!
        </p>
      </section>
      <ContactForm />
    </main>
  );
};

export default ContactPage;
