import React from 'react';
import { Link } from 'react-router-dom';
import '../css/HomePage.css';

const HomePage = () => {
  return (
    <main>
      <section className="hero">
        <h1>Welcome to Most Magical Travel Co</h1>
        <p>Making your trips to the most magical places easy, stress-free, and unforgettable.</p>
        <Link to="/contact" className="cta-button">Plan Your Magical Journey</Link>
      </section>

      <section className="services">
        <h2>Our Services</h2>
        <div className="service-cards">
          <div className="card">
            <img src="/images/planning1.jpg" alt="Planning" />
            <h3>Trip Planning</h3>
            <p>We help you design the perfect Disney World itinerary tailored to your family.</p>
          </div>
          <div className="card">
            <img src="/images/tickets.jpg" alt="Tickets" />
            <h3>Ticket Assistance</h3>
            <p>Navigate ticket options and avoid the confusion of picking the right packages.</p>
          </div>
          <div className="card">
            <img src="/images/reservations2.jpg" alt="Reservations" />
            <h3>Reservations</h3>
            <p>We handle your dining, attractions, and lodging reservations for a seamless trip.</p>
          </div>
        </div>
      </section>
    </main>
  );
};

export default HomePage;
